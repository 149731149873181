import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import homeStyle from "./home.module.css";
import { useTranslation } from "react-i18next";
import { useGetLuxuriousRoom } from "../../services/queries";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { BASE_URL } from "../../services/api";
import Loader from "../loading/loadingPage";
import { Link } from "react-router-dom";

export function LuxuriousRooms() {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const { data: luxuriousRoom, isLoading } = useGetLuxuriousRoom(lang);

  const [popupImage, setPopupImage] = useState(null);

  if (isLoading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  let rooms = undefined;
  if (luxuriousRoom) {
    rooms = Object.values(luxuriousRoom);
  }

  // Close popup handler
  const closePopup = () => setPopupImage(null);

  return (
    <>
      <section className={`${homeStyle.bgHotelRoom} mb-5`}>
        <div className={`p-md-5 py-5 text-center ${homeStyle.layer}`}>
          <div className="d-flex justify-content-center">
            <div>
              <h2 className="text-uppercase border-bottom border-primary pb-2">
                {t("Luxurious Rooms")}
              </h2>
              <p className="pt-3">
                {t("All rooms are designed for your comfort")}
              </p>
            </div>
          </div>
          <div className="container">
            <div className="row align-items-center justify-content-center">
              {rooms?.map((room,index) => (
                <div className="col-md-6 col-xl-4" key={room.id}>
                 <div className="pt-5 pb-3 m-3 bg-white border border-primary-me shadow rounded-4">
  {/* Swiper Slider */}
  <Swiper
    modules={[Navigation, Pagination, Autoplay]}
    autoplay={{
      delay: 3000 + index * 500, // Add 500ms difference for each slider based on the index
      disableOnInteraction: false, // Continue autoplay after user interaction
    }}
    navigation
    pagination={{ clickable: true }}
    spaceBetween={10}
    slidesPerView={1}
    className="w-75  rounded-4"
  >
    {room?.images?.map((image, idx) => (
      <SwiperSlide key={idx}>
        <img
          className="img-fluid rounded-3"
          src={
            BASE_URL + image ||
            "https://via.placeholder.com/300"
          }
          alt={`Room image ${idx + 1}`}
          onClick={() => setPopupImage(BASE_URL + image)} // Open popup on click
          style={{
            cursor: "pointer",
            width: "300px", // Set your desired width
            height: "250px", // Set your desired height
            objectFit: "cover", // Ensures the image fits within the dimensions without distortion
          }}
        />
      </SwiperSlide>
    ))}
  </Swiper>

  {/* Room Details */}
  <div className="pt-3 px-5">
    <h5>{room.name}</h5>
    <p>{room.description}</p>
    <p className="mt-2">
      <strong>Features:</strong>{" "}
      {room.features
        ?.map((feature) => feature.name)
        .join(", ") || "No features available"}
    </p>
    <p>
      <strong>Maximum Capacity:</strong>{" "}
      {room.maximumCapacity}
    </p>
    <p>
      <strong>Hotel:</strong> {room.hotelName}
    </p>
  </div>
</div>

                </div>
              ))}
            </div>
          </div>
          <div className="pt-4 ">
          <Link
                to="/book-room/1"
                className="btn bgPrimaryColor w-25 text-white rounded-5 btn-lg custom-btn block"
              >
                Book Now
              </Link>
          </div>
 
        </div>
       
      </section>

      {/* Popup Modal */}
      {popupImage && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1000,
          }}
          onClick={closePopup} // Close popup when clicking outside the image
        >
          <img
            src={popupImage}
            alt="Popup"
            style={{
              maxWidth: "90%",
              maxHeight: "90%",
              borderRadius: "10px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            }}
          />
        </div>
      )}
    </>
  );
}
