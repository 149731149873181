import React, { useState } from 'react';
import accountStyle from './account.module.css';
import img from '../../assets/account/Image Placeholder.png';
import upload from '../../assets/account/Vector (14).png';
import edit from '../../assets/account/Frame 241.png';
import Account from './account';
import PaymentMethod from './paymentMethod';
import { Link } from 'react-router-dom';
import BookRoom from '../../booking/bookRoom';

export default function AccountFlow() {
  const [openToggle, setOpenToggle] = useState(null);

  const handleToggle = (toggleId) => {
    setOpenToggle((prev) => (prev === toggleId ? null : toggleId));
  };

  return (
    <>
      <br /> <br />
      <section className='container pt-5'>
        <div className={`${accountStyle.accountBg} position-relative my-5`}>
          <div className='position-absolute top-50 end-0 pt-5 mt-5 pe-5'>
            <button className='btn bgPrimaryColor text-white'>
              <img src={upload} className='pe-2' alt='profile picture' />
              Upload new cover
            </button>
          </div>
          <div className='position-absolute top-100 start-50 translate-middle pt-5 mt-3'>
            <div className='position-relative'>
              <div className='position-absolute start-50 top-100 translate-middle'>
                <div className='position-relative border rounded-circle border-5 border-primary-me'>
                  <img src={img} alt='profile picture' />
                  <div className='position-absolute top-50 start-50 ms-2 mt-4'>
                    <img src={edit} className='ps-2 ms-4' alt='edit' />
                  </div>
                </div>
                <div className='text-center ps-3'>
                  <h3 className='pt-3'>John Carter</h3>
                  <p className='text-muted'>john.doe@gmail.com</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* toggle */}
        <br /><br /><br />
        <div className='toggle mt-5 pt-5'>
          <div className='row m-auto text-center'>
            <div className='col-md-4 border-end'>
              <Link
                className=' w-100 text-decoration-none'
                onClick={() => handleToggle(1)}
                aria-expanded={openToggle === 1}
              >
                Account
              </Link>
            </div>
            <div className='col-md-4 border-end'>
              <Link
                className=' w-100 text-decoration-none'
                onClick={() => handleToggle(2)}
                aria-expanded={openToggle === 2}
              >
                Tickets/Bookings
              </Link>
            </div>
            <div className='col-md-4'>
              <Link
                className=' w-100 text-decoration-none'
                onClick={() => handleToggle(3)}
                aria-expanded={openToggle === 3}
              >
                Payment methods
              </Link>
            </div>
          </div>
          <div>
            <div className={`collapse ${openToggle === 1 ? 'show' : ''}`}>
              <div className='card card-body mt-5'>
           <Account/>
              </div>
            </div>
            <div className={`collapse ${openToggle === 2 ? 'show' : ''}`}>
              <div className='card card-body mt-5'>
               <BookRoom/>
              </div>
            </div>
            <div className={`collapse ${openToggle === 3 ? 'show' : ''}`}>
              <div className='card card-body mt-5'>
               <PaymentMethod/>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
