const initialState = {
   dates: null,
 };
 
 const datesReducer = (state = initialState, action) => {
   switch (action.type) {
       case 'SET_DATES':
       return {
         ...state,
         dates: action.payload,
       };
     default:
       return state;
   }
 };
 
 export default datesReducer;
 