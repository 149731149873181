import React, { useState } from "react";
import aquaStyle from "./plazaAquaPark.module.css";

import { Link, useParams } from "react-router-dom";
import { FamilyRoom } from "../../sections/familyRoom/familyRoom";
import { StandardRoom } from "../../sections/standerdRoom/standerdRoom";
import { HotelSwimmingPools } from "../../sections/swimminPools/hotelSwimmingPools";
import { RecentlyViewed } from "../../sections/recentlyViewed/RecentlyViewed";
import { AllRooms } from '../../sections/allRooms/allRooms'

export const PlazaAquaPark = () => {
  const { hotelId } = useParams();
  return (
    <>
      <section>
        <div
          className={`vh-50 text-center d-flex justify-content-center align-items-center ${aquaStyle.bgImage}`}
        >
          <div className="w-75 fade-in-animation">
            <h1 className="text-white pb-3 fs-7 BigshotOneFont">Regency Plaza Aqua Park</h1>
            <div className={`py-5 rounded-5 layer`}>
              <h4>Spend your vacation with our activities & Hotel</h4>
              <p>Discover the best space for you</p>
              <Link
                to="/book-room/1"
                className="btn bgPrimaryColor text-white rounded-5 btn-lg custom-btn"
              >
                Book Now
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-light py-5">

        <div className=" container m-auto text-white">
        <div className="py-3 text-black">
      <h2 className=" volkhovFont">Top Categories</h2>
   </div>
   <div className="row gy-4">
  <div className="col-md-4 pe-3">
    <Link
      to={`/hotel/${hotelId}/rooms`}
      className={`text-decoration-none d-flex justify-content-center align-items-end rounded-5 cursor-pointer hover-effect ${aquaStyle.bgImage1}`}
    >
      <h5 className="fs-4 fw-semibold pb-5 text-white">Rooms</h5>
    </Link>
  </div>
  <div className="col-md-4 pe-3">
    <Link
      to={`/regency-facilities/${hotelId}/${4}`}
      className={`text-decoration-none d-flex justify-content-center align-items-end rounded-5 cursor-pointer hover-effect ${aquaStyle.bgImage6}`}
    >
      <h5 className="fs-4 fw-semibold pb-5 text-white ">Beach</h5>
    </Link>
  </div>
  <div className="col-md-4">
    <Link
      to={`/main-restaurants/${hotelId}`}
      className={`text-decoration-none d-flex justify-content-center align-items-end rounded-5 cursor-pointer hover-effect ${aquaStyle.bgImage5}`}
    >
      <h5 className="fs-4 fw-semibold pb-5 text-white ">Dining</h5>
    </Link>
  </div>
  <div className="col-md-4 pe-3">
    <Link
      to={`/regency-facilities/${hotelId}/${2}`}
      className={`text-decoration-none d-flex justify-content-center align-items-end rounded-5 cursor-pointer hover-effect ${aquaStyle.bgImage2}`}
    >
      <h5 className="fs-4 fw-semibold pb-5 text-white ">
        Conferences & Meetings
      </h5>
    </Link>
  </div>
  <div className="col-md-4 pe-3">
    <Link
      to={`/regency-facilities/${hotelId}/${1}`}
      className={`text-decoration-none d-flex justify-content-center align-items-end rounded-5 cursor-pointer hover-effect ${aquaStyle.bgImage3}`}
    >
      <h5 className="fs-4 fw-semibold pb-5 text-white ">Gym & Spa</h5>
    </Link>
  </div>
  <div className="col-md-4">
    <Link
      to={`/regency-facilities/${hotelId}/${3}`}
      className={`fs-4 fw-semibold text-decoration-none d-flex justify-content-center align-items-end rounded-5 cursor-pointer hover-effect ${aquaStyle.bgImage4}`}
    >
      <h5 className="fs-4 fw-semibold pb-5 text-white text-decoration-none">
        Shopping area
      </h5>
    </Link>
  </div>
</div>

        </div>
      </section>
      <section className="mt-5">

        <div className={`${aquaStyle.bgWhyUs}`}></div>
<div
  className={`text-center d-flex justify-content-center align-items-center p-5 ${aquaStyle.bgWhyUsContent}`}
>
<div className="container text-white">
          <div className=" text-center p-3">
          <h2 className="h1 volkhovFont">Why Us?</h2>
        </div>
  <div className="row justify-content-center align-items-center pb-5">
    <div className="col-md-6">
      <div className="d-flex justify-content-center align-items-center">
        <div className="col-2">
          <h2 className="h1" style={{ fontSize: '3rem' }}>1</h2>
        </div>
        <div className="col-10 text-start">
          <h5 style={{ fontSize: '1.33rem', lineHeight: '1.5' }}>
            Exquisite Dining Experiences
          </h5>
          <p style={{ fontSize: '1rem', lineHeight: '1.6' }}>
            Indulge in a variety of world-class dining options at our hotel. From fine dining restaurants to casual eateries, we offer an extensive menu featuring international and local cuisines, prepared by top chefs.
          </p>
        </div>
      </div>
    </div>
    <div className="col-md-6">
      <div className="d-flex justify-content-center align-items-center">
        <div className="col-2">
          <h2 className="h1" style={{ fontSize: '3rem' }}>2</h2>
        </div>
        <div className="col-10 text-start">
          <h5 style={{ fontSize: '1.33rem', lineHeight: '1.5' }}>
            Luxurious Rooms & Suites
          </h5>
          <p style={{ fontSize: '1rem', lineHeight: '1.6' }}>
            Our elegantly designed rooms and suites provide the ultimate comfort with modern amenities and stunning views. Whether you’re staying for business or leisure, you’ll find a perfect space to relax and unwind.
          </p>
        </div>
      </div>
    </div>
    <div className="col-md-6 pt-4">
      <div className="d-flex justify-content-center align-items-center">
        <div className="col-2">
          <h2 className="h1" style={{ fontSize: '3rem' }}>3</h2>
        </div>
        <div className="col-10 text-start">
          <h5 style={{ fontSize: '1.33rem', lineHeight: '1.5' }}>
            Gym & Spa for Total Relaxation
          </h5>
          <p style={{ fontSize: '1rem', lineHeight: '1.6' }}>
            Our state-of-the-art fitness center is equipped with the latest equipment for a complete workout. Afterward, unwind at our luxurious spa, where you can enjoy a variety of treatments designed to rejuvenate your body and mind.
          </p>
        </div>
      </div>
    </div>
    <div className="col-md-6 pt-4">
      <div className="d-flex justify-content-center align-items-center">
        <div className="col-2">
          <h2 className="h1" style={{ fontSize: '3rem' }}>4</h2>
        </div>
        <div className="col-10 text-start">
          <h5 style={{ fontSize: '1.33rem', lineHeight: '1.5' }}>
            Private Beach and Outdoor Pools
          </h5>
          <p style={{ fontSize: '1rem', lineHeight: '1.6' }}>
            Our hotel offers exclusive access to a pristine beach, where you can relax by the sea. In addition, enjoy our outdoor pools, perfect for a refreshing swim or lounging in the sun, creating the ideal vacation setting.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

</div>

      </section>
      <RecentlyViewed/>
<AllRooms/>
<HotelSwimmingPools />

      {/* <FamilyRoom />
      <StandardRoom /> */}
    </>
  );
};
