import aboutUs from '../../assets/contact/bg style.png'
import aboutUs2 from '../../assets/contact/Ellipse 626.png'
import goals from '../../assets/contact/Group 194 (1).png'
import romantic from '../../assets/contact/Illustration.png'
import goals2 from '../../assets/contact/image 919.png'
import aboutStyle from './about.module.css'
import ReviewsSlider from '../sections/reviewsSlider/reviewsSlider'
import { useTranslation } from 'react-i18next';

export default function About(){
   const { t } = useTranslation();

   return<>
   <section className={` ${aboutStyle.bannerBg}`}>
      <div className={`d-flex justify-content-center align-items-center ${aboutStyle.bannerBgColor}`}>
<div className='text-center px-5 '>
   <div className='layer rounded-5 p-3 fade-in-animation'>
   <h1 className='BigshotOneFont text-white fs-7'>{t("about_us_page.about_us")}</h1>
   <p className='text-white'>
  Welcome to our hotel, a destination where luxury meets comfort. With our prime location, exceptional amenities, and unparalleled service, we strive to create memorable experiences for every guest. From elegant rooms and gourmet dining to relaxing spa treatments and thrilling recreational activities, our hotel is designed to cater to your every need. Whether you're here for business, leisure, or a family getaway, our dedicated team is committed to making your stay truly unforgettable.
</p>
   </div>

</div>
      </div>
   </section>
   <section className="container my-5 pb-5">
      <div className="row align-items-center justify-content-center">
        <div className="col-md-6">
         <div className='px-5'>
<h6 className='textPrimaryColor'>{t("about_us_page.about_us")}</h6>
<h3>{t("about_us_page.about_us_subtitle")}</h3>
<p>{t("about_us_page.about_us_description")}</p>
        </div>
         </div>
        <div className="col-md-6  order-first order-md-3">
<div className=''>
<img src={aboutUs} className='w-60 position-absolute' alt="" />
<img src={aboutUs2} className='w-75 position-relative top-20 start-20' alt="" />
</div>
        </div>
      </div>
      </section>

      <section className={` ${aboutStyle.wanderlustBg}`}>
      <div className={`d-flex justify-content-center align-items-center h-100 ${aboutStyle.overLay}`}>
<div className='pt-5'>
   <h1 className='BigshotOneFont text-white fs-7'>{t("about_us_page.wanderlust")}</h1>
</div>
      </div>
   </section>

   <section className="container my-5">
      <div className="row align-items-center justify-content-center position-relative ">
        <div className="col-md-6">
<div className=''>
<img src={goals} className='w-100' alt="" />
</div>
        </div>
        <div className="col-md-6  ">
         <div className='position-absolute top-0 end-0'>
         <img src={goals2} className='w-100' alt="" />
         </div>
         <div className='px-5'>
<h2 className='BigshotOneFont'>{t("about_us_page.goals_title")}</h2>
<p>{t("about_us_page.goals_description")}</p>       
 </div>
         </div>
      </div>
      </section>
      <section className="container my-5">
      <div className="row align-items-center justify-content-center">
        <div className="col-md-6 order-first order-md-3">
<div className=''>
<img src={romantic} className='w-100' alt="" />
</div>
        </div>
        <div className="col-md-6">
         <div className='px-5'>
<h2 className='BigshotOneFont h1'>{t("about_us_page.romantic_destinations_title")}</h2>
<p>{t("about_us_page.romantic_destinations_description")}</p>
 </div>
         </div>
      </div>
      </section>

   <ReviewsSlider/>

   </>
}