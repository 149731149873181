import view3 from "../../assets/recentely view/image 36.png";
import check from "../../assets/recentely view/check 4.png";
import './facilites.css';
import { useParams } from "react-router-dom";
import { useGetHotelRooms } from "../../services/queries";
import { useTranslation } from 'react-i18next';
import { BASE_URL } from "./services/api";
import Loader from "../loading/loadingPage";
import "./rooms.css";

export const AllRooms = () => {
  const { hotelId } = useParams();
  const { i18n } = useTranslation();
  const { data: hotelRooms, isLoading } = useGetHotelRooms({ hotelId, lang: i18n.language });
  const regency = "Regency Plaza Aqua Park Rooms";
  const royal = "Royal Regency Club Rooms";
  const romance = "Romance Regency Club Rooms";
  if (isLoading) {
    return <div><Loader/></div>;
  }
if (hotelRooms) {
 console.log(hotelRooms);
}
  return (
    <section className=" m-auto row pt-5">
      <div className="roomsHero d-flex justify-content-center align-items-center">
        <div className="fade-in-animation ">
          <div className="layer2 p-5 rounded-5 text-center fade-in-animation">
        {hotelId == 1 && <h1 className=" fw-bold">{regency}</h1>}
        {hotelId == 2 && <h1 className=" fw-bold">{royal}</h1>}
        {hotelId == 3 && <h1 className=" fw-bold">{romance}</h1>}
        <p>explore our uniques rooms especially for you</p>
          </div>

        </div>
      
      </div>
      <div>
        
      </div>
<div className=" ms-3 row ">
{hotelRooms.map((room) => (
        <div className="py-4 col-md-4 cursor-pointer" key={room.id}>
          <div className="card me-4 rounded-4 shadow border my-3 cardPosition">
            <img src={BASE_URL+room.images[0] || view3} className="card-img-top rounded-top-4" alt={room.name} />
            <div className="fav">
            </div>
            <div className="card-body">
              <div className="d-flex justify-content-between">
               <div>
                <h5 className="card-title">{room.name}</h5>
                <span className="text-muted">{room.nights} Nights</span>
               </div>

                <div>
                <div className="d-flex justify-content-center align-items-center">
                  <h4 className="ms-2 mb-0 me-2">{room.child+room.adult}</h4>
                  <i class="fa-solid fa-user-group fs-5"></i>                
                  </div>
<div className="d-flex">
   <span className="text-muted">{room.adult} adult</span>
   <span className="text-muted">, {room.child} child</span>

</div>
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center py-3 text-muted">
               {room?.features?.map((feature)=>(
                <div className="item">
                  <div className="d-flex justify-content-center">
                    <img src={BASE_URL+feature.image} alt="person" />
                  </div>
                  <span>{feature.name}</span>
                </div>
               ))}
              </div>
              <div>
                <div className="d-flex">
                  <div className="pe-3">
                    <img src={check} alt="check" />
                  </div>
                  <p className="mb-0">High Speed Wifi</p>
                </div>
                <div className="d-flex">
                  <div className="pe-3">
                    <img src={check} alt="check" />
                  </div>
                  <p className="mb-0">Shower</p>
                </div>
                <div className="d-flex">
                  <div className="pe-3">
                    <img src={check} alt="check" />
                  </div>
                  <p className="mb-0">Air-Conditioning</p>
                </div>
                <div className="d-flex">
                  <div className="pe-3">
                    <img src={check} alt="check" />
                  </div>
                  <p className="mb-0">Parking</p>
                </div>
              </div>
              <div className="d-flex py-4">
                <h3 className="pe-2">₹{room.total}</h3>
                <span className="text-muted pt-2">Total</span>
              </div>
            </div>
          </div>
        </div>
      ))}
</div>
    </section>
  );
};
