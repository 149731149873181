import React from "react"
import romanceStyle from './romanceRegency.module.css'
import bags from './../../../assets/Main/Group (1).png'
import bed from './../../../assets/Main/Vector.png'
import hotel from './../../../assets/Main/hotel.png'
import whyChooseUs from './../../../assets/hotels/illustrasi.png'
import { Categories } from '../../sections/categories/categories'
import { RecentlyViewed } from '../../sections/recentlyViewed/RecentlyViewed'
import { AllRooms } from '../../sections/allRooms/allRooms'
import {Offers } from '../../sections/offers/offers'
import { Link, useParams } from "react-router-dom"
import { HotelSwimmingPools } from "../../sections/swimminPools/hotelSwimmingPools"

export const RomanceRegencyClub = ()=>{
   const{hotelId} = useParams()
   return<>
   {/* hero section */}
   <section className={`d-flex justify-content-between align-items-top ${romanceStyle.bgImage}`}>
  <div>
    <div className="col-md-6 ps-5 ms-5 fade-in-animation">
      <div className={`${romanceStyle.fixedHight}`}></div>
      <h1 className="BigshotOneFont display-3  ">
        Spend your vacation with           <span className="pb-3 fs-8 text-primary-me BigshotOneFont">Romance Regency Club</span>

      </h1>
      <p className="fs-4 text-primary-me">
        Welcome to our hotel, where luxury and relaxation await, where hospitality meets comfort.
      </p>
      <Link 
        to="/book-room/3" 
        className="btn bgPrimaryColor text-white rounded-5 btn-lg custom-btn"
      >
        Book Now
      </Link>
    </div>
  </div>
</section>

      <Categories hotelId={hotelId}/>
   {/* Why Choose Us */}
<section className="d-flex justify-content-center align-items-center pt-5 my-5">
<div className="col-md-6 d-flex ">
   <img src={whyChooseUs} className="w-75" alt="" />
</div>
<div className="col-md-5">
  <div className="mb-4 text-center">
    <h4 className="text-primary-me fw-bold">Why Choose Us</h4>
    <p className="text-muted fst-italic">
      Welcome to our hotel, where hospitality meets comfort.
    </p>
  </div>

  <div
    className={`d-flex align-items-center p-4 mb-3 rounded shadow-sm bg-light cursor-pointer ${romanceStyle.item}`}
  >
    <div className="me-3">
      <img
        src={bed}
        className="border  rounded-circle border-primary-me  p-2"
        alt="Comfort"
        style={{ width: "60px", height: "60px" }}
      />
    </div>
    <div>
      <h6 className="fw-bold text-dark mb-2">Comfort</h6>
      <p className="text-muted mb-0">
        Our hotel offers comfortable rooms and suites equipped with the latest
        amenities to ensure an unforgettable stay.
      </p>
    </div>
  </div>

  <div
    className={`d-flex align-items-center p-4 mb-3 rounded shadow-sm bg-light cursor-pointer ${romanceStyle.item}`}
  >
    <div className="me-3">
      <img
        src={hotel}
        className="rounded-circle border border-primary-me p-2"
        alt="Prime Location"
        style={{ width: "60px", height: "60px" }}
      />
    </div>
    <div>
      <h6 className="fw-bold text-dark mb-2">Prime Location</h6>
      <p className="text-muted mb-0">
        Our hotel is located in the heart of the city, providing easy access to
        major attractions and essential facilities.
      </p>
    </div>
  </div>

  <div
    className={`d-flex align-items-center p-4 rounded shadow-sm bg-light cursor-pointer ${romanceStyle.item}`}
  >
    <div className="me-3">
      <img
        src={bags}
        className="rounded-circle border border-primary-me p-2"
        alt="Excellent Service"
        style={{ width: "60px", height: "60px" }}
      />
    </div>
    <div>
      <h6 className="fw-bold text-dark mb-2">Excellent Service</h6>
      <p className="text-muted mb-0">
        We offer exceptional customer service around the clock to meet all your
        needs and ensure your complete satisfaction.
      </p>
    </div>
  </div>
</div>

</section>
<RecentlyViewed/>
<AllRooms/>
{/* <Offers/> */}
<HotelSwimmingPools/>

{/* <Reviews/> */}
   </>
}