import royalRegencyStyle from './royalRegency.module.css'
import img from '../../../assets/hotels/image 1 (1).png'
import bgHotel from '../../../assets/hotels/Group 194.png'
import Navbar from '../../navbar/navbar'
import { Categories } from '../../sections/categories/categories'
import { RecentlyViewed } from '../../sections/recentlyViewed/RecentlyViewed'
import { AllRooms } from '../../sections/allRooms/allRooms'
import { Offers } from '../../sections/offers/offers'
import { Footer } from '../../footer/footer'
import { Link, useParams } from 'react-router-dom'
import { HotelSwimmingPools } from '../../sections/swimminPools/hotelSwimmingPools'

export const RoyalRegency =()=>{
   const{hotelId} = useParams()
   return<>
   <section>
      <div className={`vh-100 text-center d-flex justify-content-center align-items-center ${royalRegencyStyle.bgImage}`}>
      <div className="w-75 fade-in-animation">
            <h1 className="text-white pb-3 fs-7 BigshotOneFont">Royal Regency Club</h1>
            <div className={`py-5 rounded-5 layer2`}>
              <h4>Spend your vacation with our activities & Hotel</h4>
              <p className=''>Discover the best space for you</p>
              <Link
                to="/book-room/1"
                className="btn bgPrimaryColor text-white rounded-5 btn-lg custom-btn"
              >
                Book Now
              </Link>
            </div>
          </div>
      </div>
   </section>
   <Categories hotelId={hotelId}/>
   <AllRooms/>
<section className={`${royalRegencyStyle.bgHotelRoom} mb-5 `}>
   <div className='d-flex justify-content-center align-items-center'>
<div className='col-md-6'>
   <img src={bgHotel} alt="bgHotel" srcSet="" />
</div>
<div className='col-md-4'>
   <div className='item text-white'>
      <h2 className='BigshotOneFont fs-7'>Royal regency CLub</h2>
   </div>
   <div className=' text-white'>
<div className='mb-4'>
<span className='border-end px-3 me-3'>Sharm</span>
<span>Great Reviews</span>
</div>
<div className='pe-5'>
<p>Welcome to our grand unveiling! We are thrilled to open the doors of our new hotel and invite you to embark on a journey of luxury, comfort, and exceptional hospitality.</p>
</div>
<div className='border-end w-25'>
<Link to='/book-room/2' className='btn bgPrimaryColor rounded-5 text-white btn-lg custom-btn'>Book Now</Link>
</div>
   </div>
</div>
   </div>
</section>
   <RecentlyViewed/>
   {/* <Offers/> */}
<HotelSwimmingPools/>

   </>
}