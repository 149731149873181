import { useQuery } from "@tanstack/react-query";
import { GetAllRoomTypes } from "./api";
import { GetHomeFacilities, GetLuxuriousRoom, GetServices } from "./getApis";
import { getAllHotelRooms, GetHomeReviews } from "../components/facilites/services/api";

export function useGetServices(lang){
   return useQuery({
      queryKey: ['GetServices',lang],
      queryFn: () => GetServices(lang),
   })
}

export function useGetHomeFacilities(lang) {
  return useQuery({
    queryKey: ['GetHomeFacilitie', lang],
    queryFn: () => GetHomeFacilities(lang),
  });
}


export function useGetLuxuriousRoom(lang){
   return useQuery({
      queryKey: ['GetLuxuriousRoom',lang],
      queryFn:()=> GetLuxuriousRoom(lang),
   })
}

export function useRoomsTypes(lang){
   return useQuery({
      queryKey: ['rooms',lang],
      queryFn:()=> GetAllRoomTypes(lang),
   })
}

export function useGetHotelRooms({lang,hotelId}){
   return useQuery({
      queryKey: ['hotelRooms',lang,hotelId],
      queryFn:()=> getAllHotelRooms({lang,hotelId}),
   })
}

export function useGetHomeReviews(){
   return useQuery({
      queryKey: ['GetHomeReviews'],
      queryFn:()=> GetHomeReviews(),
   })
}

