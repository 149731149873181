import React, { useEffect, useState } from "react";
import { DateRangePicker } from "react-date-range";
import { addDays } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { setDates } from "../store/actions/dateActions";

const DateRange = ({ onDateSelect }) => {
  const dispatch = useDispatch();
  const storeDates = useSelector((state) => state.date.dates);

  const [state, setState] = useState([
    {
      startDate: storeDates ? storeDates.startDate : null,
      endDate: storeDates ? storeDates.endDate : addDays(0, 0),
      key: "selection",
    },
  ]);
  useEffect(() => {
    // Log and dispatch dates whenever the state changes
    if (state[0].startDate && state[0].endDate) {
      onDateSelect(state);
      dispatch(setDates(state[0])); // Dispatch the selected range
    }
  }, [state, onDateSelect, dispatch]);
  function printPrime(n, m) {
    // if ( n >= 1 &&  m >= 1 && n > m && n > 1000000){
    // return false
    // }
    // for (let i = 2 ; i>n && < m && i %2 != 0 && i % 3 != 0; i++){
    //   return [i]
    // }
  }

  return (
    <div>
      <DateRangePicker
        onChange={(item) => setState([item.selection])}
        showSelectionPreview={true}
        moveRangeOnFirstSelection={false}
        months={2}
        ranges={state}
        direction="horizontal"
        staticRanges={[]}
        inputRanges={[]}
      />
    </div>
  );
};

export default DateRange;
