import { Link } from 'react-router-dom'
import award from '../../assets/awards/2239955444397433276_1271741983.jpg'
import award2 from '../../assets/awards/2288566068081396058_1271741983.jpg'
import award3 from '../../assets/awards/2532012933065887466.jpg'
import award4 from '../../assets/awards/2573512139139913458.jpg'
export const Awards = ()=>{

   return<>
   <section className="container m-auto py-5">
      <div className="row align-items-center">
         <Link to='https://www.tripadvisor.com/Hotel_Review-g303855-d27530845-Reviews-Romance_Regency_Club-Nabq_Bay_Sharm_El_Sheikh_South_Sinai_Red_Sea_and_Sinai.html' className="col-md-3">
            <img src={award} className='w-100' alt="award" srcset="" />
         </Link>
         <Link to='https://www.tripadvisor.com/Hotel_Review-g303855-d27530845-Reviews-Romance_Regency_Club-Nabq_Bay_Sharm_El_Sheikh_South_Sinai_Red_Sea_and_Sinai.html' className="col-md-3">
            <img src={award2} className='w-100' alt="award" srcset="" />
         </Link>
         <Link to='https://www.tripadvisor.com/Hotel_Review-g303855-d27530845-Reviews-Romance_Regency_Club-Nabq_Bay_Sharm_El_Sheikh_South_Sinai_Red_Sea_and_Sinai.html' className="col-md-3">
            <img src={award3} className='w-100' alt="award" srcset="" />
         </Link>
         <Link to='https://www.tripadvisor.com/Hotel_Review-g303855-d27530845-Reviews-Romance_Regency_Club-Nabq_Bay_Sharm_El_Sheikh_South_Sinai_Red_Sea_and_Sinai.html' className="col-md-3">
            <img src={award4} className='w-100' alt="award" srcset="" />
         </Link>
         </div> 
   </section>
   </>
}