import { _axios } from "./api";
import { useTranslation } from 'react-i18next';

export const GetServices = async(lang)=>{
   const {data} = await _axios.get(`/api/Services/GetServices/${lang}`);
   return data;
 }

 export const GetHomeFacilities = async(lang)=>{
   const {data} = await _axios.get(`/api/Facilities/GetHomeFacility/${lang}`);
   return data;
 }

 export const GetLuxuriousRoom = async(lang)=>{
   const {data} = await _axios.get(`/api/Room/GetLuxurioustRoom/${lang}`);
   return data;
 }