import { useRef } from "react";
import Slider from "react-slick";

import img1 from "../../assets/homeSlider/step=1.png";
import img2 from "../../assets/homeSlider/step=2.png";
import img3 from "../../assets/homeSlider/step=3.png";
import img4 from "../../assets/homeSlider/step=4.png";
import img5 from "../../assets/homeSlider/step=5.png";
import location from "../../assets/Main/↳ Primary icon.png";
import sliderBg from "../../assets/homeSlider/Rectangle 1.png";
import sliderBg2 from "../../assets/homeSlider/Rectangle 2 (1).png";
import sliderBg3 from "../../assets/homeSlider/Rectangle 3.png";
import sliderBg4 from "../../assets/homeSlider/Rectangle 4.png";
import sliderBg5 from "../../assets/homeSlider/Rectangle 5.png";
import prev from "../../assets/Main/↳ Primary icon (1).png";
import nextIcon from "../../assets/Main/↳ Primary icon (2).png";
import './home-slider.css'
function Carousel() {
  let sliderRef1= useRef(null);
  let sliderRef2= useRef(null);

  var settings1 = {
    vertical: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,

  };

const settings2 = {
  arrows: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,    
  autoplay: true,
  centerMode: true,
  centerPadding: '30px', 
  responsive: [
    {
      breakpoint: 900,
      settings: {
        centerPadding: '20px',
      }
    },
    {
      breakpoint: 650,
      settings: {
        centerPadding: '10px',
      }
    }
  ]
};

  const next = () => {
    sliderRef1.slickPrev();
    sliderRef2.slickPrev();
  };

  const previous = () => {
   
    sliderRef1.slickNext();
    sliderRef2.slickNext();
  };

  return (
    <div className="mainSlider overflow-hidden ">
      <div className="position-relative ">
        {/* slider in background  */}
        <div className="">
          <Slider
            ref={(slider) => {
              sliderRef1 = slider;
            }}
            {...settings1}
          >
            <div  className="position-relative bg-info-subtle">
              <img className="w-50-me " src={sliderBg} alt="" />
              <div className=" desc text-center text-md-start  w-50-me px-5 pb-5 mb-5 text-white position-absolute bottom-me">
                <div>
                {/* <div>
               <p className="d-flex"><span className="pe-2"><img src={location} alt="" /></span> Sharm El Sheikh </p>
                </div> */}
                <h1 className="BigshotOneFont">
                Take a brief tour through our hotel's highlights
                </h1>
                <p className="hidden">"Prepare to embark on a virtual adventure through our hotel's offerings, where each click leads you closer to discovering the hidden gems and luxurious surprises awaiting your stay."</p>
            
              </div>

                </div>
            </div>
            <div  className="position-relative bg-info-subtle">
              <img className="w-50-me " src={sliderBg2} alt="" />
              <div className=" desc text-center text-md-start w-50-me px-5 pb-5 mb-5 text-white position-absolute bottom-me ">
                <h1 className="BigshotOneFont">
                Unlock luxury, discover comfort, indulge endlessly.
                </h1>
                <p className="hidden">
                "Join us on a whirlwind tour! Experience a quick preview of our hotel's charms, setting the stage for the extraordinary journey that lies ahead during your stay."                  </p>
              </div>
            </div>
            <div  className="position-relative bg-info-subtle">
              <img className="w-50-me " src={sliderBg3} alt="" />
              <div className=" desc text-center text-md-start w-50-me px-5 pb-5 mb-5 text-white position-absolute bottom-me ">
                <h1 className="BigshotOneFont">
                "Unveil opulence, embrace comfort, immerse in luxury."               
                 </h1>
                <p className="hidden">"Welcome to the gateway of indulgence! Let's embark on a tantalizing teaser tour, offering a sneak peek into the opulent delights and unparalleled experiences that await you at our hotel."</p>
              </div>
            </div>
            <div  className="position-relative bg-info-subtle">
              <img className="w-50-me " src={sliderBg4} alt="" />
              <div className=" desc text-center text-md-start w-50-me px-5 pb-5 mb-5 text-white position-absolute bottom-me ">
                <h1 className="BigshotOneFont">
                Embrace elegance, ignite adventure, relish serenity, savor.
                </h1>
                <p className="hidden">"Ready to dive into luxury? Take a lightning tour through our hotel's highlights, igniting your anticipation for the lavish comforts and exclusive experiences that await your arrival."</p>
              </div>
            </div>
            <div  className="position-relative bg-info-subtle">
              <img className="w-50-me " src={sliderBg5} alt="" />
              <div className=" desc text-center text-md-start  w-50-me px-5 pb-5 mb-5 text-white position-absolute bottom-me ">
                <h1 className="BigshotOneFont">
                Enter the realm, luxuriate in opulence, depart.                
                </h1>
                <p className="hidden">"Prepare to embark on a virtual adventure through our hotel's offerings, where each click leads you closer to discovering the hidden gems and luxurious surprises awaiting your stay."</p>
              </div>
            </div>
          </Slider>
        </div>

        <div className="position-absolute top-0 vh-100 d-flex align-items-center">
          {/* buttons next & prev  */}
          <div className="navs position-absolute translate-middle start-50 bottom-0 mb-5 d-flex gap-4 z-3">
            <button
              onClick={previous}
              type="button"
              className=" rounded-5 bg-dark bg-opacity-50 border-0 p-2"
            >
              <img className="w-100" src={prev} alt="" />
            </button>
            <button 
            onClick={next}
             type="button"  
            className=" rounded-5 bg-dark bg-opacity-50 border-0 p-2"
            >
               <img className="w-100" src={nextIcon} alt="" />
            </button>
          </div>

          {/* slider in front  */}
          <div className="mw-100 mh-100 ">
  <div className="vw-100 ">
    <Slider
      ref={(slider) => {
        sliderRef2 = slider;
      }}
      {...settings2}
    >
      <div className="sliderItem mt-5 pt-5">
        <div
          className="item2 ms-auto sliderItemPadding "
        >
          <img
            className="w-100 h-100 object-fit-fill"
            src={img1}
            alt=""
          />
        </div>
      </div>
      <div className="sliderItem mt-5 pt-5">
        <div
          
          className="item2 ms-auto sliderItemPadding"
        >
          <img
            className="w-100 h-100 object-fit-fill"
            src={img2}
            alt=""
          />
        </div>
      </div>
      <div className="sliderItem mt-5 pt-5">
        <div
          className="item2 ms-auto sliderItemPadding"
        >
          <img
            className="w-100 h-100 object-fit-fill"
            src={img3}
            alt=""
          />
        </div>
      </div>
      <div className="sliderItem mt-5 pt-5">
        <div
          className="item2 ms-auto sliderItemPadding"
        >
          <img
            className="w-100 h-100 object-fit-fill"
            src={img4}
            alt=""
          />
        </div>
      </div>
      <div className="sliderItem mt-5 pt-5">
        <div
          className="item2 ms-auto sliderItemPadding"
        >
          <img
            className="w-100 h-100 object-fit-fill"
            src={img5}
            alt=""
          />
        </div>
      </div>
    </Slider>
  </div>
</div>
        </div>
      </div>
    </div>
  );
}

export default Carousel;
