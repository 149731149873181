import React from "react";
import { useTranslation } from "react-i18next";
import ReviewsSliderStyle from "./ReviewsSlider.module.css";

import Reviews2 from "./reviews2Slider";

function ReviewsSlider() {
  const { t } = useTranslation();
  return (
    <section className={`my-5`}>
      <div
        className={`pt-5 ${ReviewsSliderStyle.reviews}`}
      >
        <div className="pt-5 text-center">
            <h1 className="BigshotOneFont fs-1 px-5 pb-5">
              {t("about_us_page.clients_reviews")}
            </h1>
            <Reviews2/>
          </div>
        </div>
    </section>
  );
}

export default ReviewsSlider;
