import { combineReducers } from 'redux';
import roomReducer from './roomReducer';
import datesReducer from './datesReducer';

const rootReducer = combineReducers({
  room: roomReducer,
  date: datesReducer,
  // Add other reducers here in the future
});

export default rootReducer;
