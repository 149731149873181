import React from "react";
import Slider from "react-slick";
import category1 from '../../../assets/hotels/rooms.JPG'
import category2 from '../../../assets/hotels/Beach\ sun\ rise\ overview\ ok.JPG'
import category3 from '../../../assets/hotels/Rectangle\ 8.png'
import category4 from '../../../assets/hotels/Rectangle\ 11.png'
import gym from '../../../assets/hotels/Rectangle\ 10.png'
import shopping from '../../../assets/hotels/Rectangle\ 11.png'
import categoriesStyle from './categories.module.css'
import { Link } from "react-router-dom";


export const Categories = ({hotelId})=>{
   var settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,
      initialSlide: 0,
      arrows: false, // Disable arrows
      autoplay: true, // Enable autoplay
      autoplaySpeed: 2000, // Set autoplay speed to 2 seconds
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
   return<>
   <section className="container my-5">
   <div className="py-3">
      <h2 className=" volkhovFont">Top Categories</h2>
   </div>
   <div className="slider-container m-auto">
  <Slider {...settings}>
    <Link to={`/hotel/${hotelId}/rooms`} className={`pe-4 ${categoriesStyle.category}`}>
      <div className={`position-relative ${categoriesStyle.item}`}>
        <img className={`w-100 ${categoriesStyle.fixedImage}`} src={category1} alt="Rooms" />
        <h5 className={`text-white ${categoriesStyle.categoryName}`}>Rooms</h5>
      </div>
    </Link>
    <Link to={`/regency-facilities/${hotelId}/${4}`} className={` pe-4 ${categoriesStyle.category}`}>
      <div className={`position-relative ${categoriesStyle.item}`}>
        <img className={`w-100 ${categoriesStyle.fixedImage}`} src={category2} alt="Beach" />
        <h5 className={`text-white ${categoriesStyle.categoryName}`}>Beach</h5>
      </div>
    </Link>
    <Link to={`/main-restaurants/${hotelId}`} className={`pe-4 ${categoriesStyle.category}`}>
      <div className={`position-relative ${categoriesStyle.item}`}>
        <img className={`w-100 ${categoriesStyle.fixedImage}`} src={category3} alt="Dining" />
        <h5 className={`text-white ${categoriesStyle.categoryName}`}>Dining</h5>
      </div>
    </Link>
    <Link to={`/regency-facilities/${hotelId}/${2}`} className={`pe-4 ${categoriesStyle.category}`}>
      <div className={`position-relative ${categoriesStyle.item}`}>
        <img className={`w-100 ${categoriesStyle.fixedImage}`} src={category4} alt="Conferences & Meetings" />
        <h5 className={`text-white ${categoriesStyle.categoryName}`}>Conferences & Meetings</h5>
      </div>
    </Link>
    <Link to={`/regency-facilities/${hotelId}/${1}`} className={`pe-4 ${categoriesStyle.category}`}>
      <div className={`position-relative ${categoriesStyle.item}`}>
        <img className={`w-100 ${categoriesStyle.fixedImage}`} src={gym} alt="Gym & Spa" />
        <h5 className={`text-white ${categoriesStyle.categoryName}`}>Gym & Spa</h5>
      </div>
    </Link>
    <Link to={`/regency-facilities/${hotelId}/${3}`} className={`pe-4 ${categoriesStyle.category}`}>
      <div className={`position-relative ${categoriesStyle.item}`}>
        <img className={`w-100 ${categoriesStyle.fixedImage}`} src={shopping} alt="Shopping area" />
        <h5 className={`text-white ${categoriesStyle.categoryName}`}>Shopping area</h5>
      </div>
    </Link>
  </Slider>
</div>

   </section>
   </>
}