import check from "../assets/Main/check 1.png";
import React, { useEffect, useState } from "react";
import hotelCard from "../assets/hotels/hotel card.png";
import location from "../assets/Main/Location.png";
import { useSelector } from "react-redux";
import { BASE_URL } from "../services/api";
import { useRoomSearch } from "../services/mutations";
import { useGetHotelRooms } from "../services/queries";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Loader from "../components/loading/loadingPage";

const HotelCard = () => {
  const { hotelId } = useParams();
  const { i18n } = useTranslation();
  const lang = i18n.language;
  const [rooms, setRooms] = useState([]);
  // const { mutate, data, isLoading, isError, error } = useRoomSearch();
  const { data: hotelRooms, isLoading: hotelRoomsLoading } = useGetHotelRooms({
    hotelId,
    lang,
  });
  const currentRooms = useSelector((state) => state.room.rooms.currentRooms);
  useEffect(() => {
    if (currentRooms) {
      setRooms(currentRooms);
      console.log("currentRooms", rooms);
    }
  }, [currentRooms]);
  useEffect(() => {
    if (hotelRooms) {
      setRooms(hotelRooms);
      console.log("hotelRooms", hotelRooms);
    }
  }, [hotelRooms]);

  // if (isLoading) {
  //   <Loader/>
  // }
  return (
    <>
      {rooms?.map((room) => (
        <div key={room.id} className="card border-0 shadow mb-4">
          <div className="row no-gutters">
            {/* Left side: Image */}
            <div className="col-md-4">
              <img
                src={`${BASE_URL}${room.images[0]}`}
                className="card-img"
                alt="Hotel Image"
              />
            </div>

            {/* Right side: Content */}
            <div className="col-md-8">
              <div className="card-body">
                <div className="d-flex justify-content-between">
                  <div>
                    {/* Hotel Name */}
                    <h5 className="card-title">{room.hotelName}</h5>
                    {/* Hotel Details */}
                    <p className="card-text mb-1">
                      <img src={location} alt="" /> Lorem lorem lorem lorekm
                      loiejn
                    </p>
                    <h6>{room.name}</h6>
                    <p>{room.description}</p>
                  </div>
                  <div>
                    {/* Hotel Rating */}
                    <p className="card-text mt-2">
                      <small className="border p-1 rounded">4.2</small> Very
                      Good 371 reviews
                    </p>
                    <div className="text-right">
                      {/* Price */}
                      <span>
                        {room.nights} night, {room.adult} adult, {room.child}{" "}
                        child
                      </span>
                      <h4 className="text-danger">${room.total}</h4>
                    </div>
                  </div>
                </div>
                <div className='d-flex justify-content-around align-items-center'>
                {room.features.map((feature,index)=>
                <div key={index} className=''>
                  <span className='d-flex justify-content-around align-items-center'>
                    <img  
                     src={feature.image ? `${BASE_URL}${feature.image}` : check} 
                    alt="" srcSet="" />
                    </span>
                    <p>{feature.name}</p>
                </div>
                )}
                </div>
                <div className=" border-bottom border-cyan-100 my-4"></div>
                {/* CTA Buttons */}
                <div className="row justify-content-around mt-3">
                  <div className="col-2 d-flex justify-content-center align-items-center border rounded cursor-pointer">
                    <i className="far fa-heart"></i>
                  </div>
                  <button className="col-9 btn bgPrimaryColor text-white">
                    View Place
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default HotelCard;
