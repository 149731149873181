import React from "react";
import { Link, NavLink, useNavigate } from "react-router-dom"; // Use NavLink instead of Link
import logo from "../../assets/Login/Regency Plaza Hotels 2.png";
import LanguageDropdown from "./languages";
import avatar from '../../assets/Main/avatar.png'
export default function Navbar() {
  const user =localStorage.getItem('user')
  const navigate = useNavigate()
  const handleUserAccount = ()=>{
    navigate('/account')

  }
  return <>
  <nav class="position-fixed top-20 left-2 z-index py-0 w-95 navbar navbar-expand-lg navbar-light bg-light border border-info rounded-5 ">
  <div class="container-fluid">
    <Link class="navbar-brand ps-lg-2 " to="/">
    <img className="" src={logo} alt="regency logo" />
    </Link>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse row justify-content-around " id="navbarSupportedContent">
    <ul className="navbar-nav col-12 col-lg-7 ms-3 ms-lg-0">
              <li className="nav-item me-3">
                <NavLink
                  className="nav-link"
                  to="/"
                  activeClassName="active"
                  exact
                >
                  Home
                </NavLink>
              </li>
              <li className="nav-item dropdown me-3">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdownMenuLink"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Explore
                </a>
                <ul
  className="dropdown-menu"
  aria-labelledby="navbarDropdownMenuLink"
>
  <li>
    <NavLink
      className={({ isActive }) =>`dropdown-item ${isActive ? 'bg-primary-color2' : ''}`}
      to="/plaza-aqua-park/1"
    >
      Regency Plaza Aqua Park
    </NavLink>
  </li>
  <li>
    <NavLink
      className={({ isActive }) =>
        `dropdown-item ${isActive ? 'bg-primary-color2' : ''}`
      }
      to="/royal-regency-hotel/2"
    >
      Royal Regency Club
    </NavLink>
  </li>
  <li>
    <NavLink
      className={({ isActive }) =>
        `dropdown-item ${isActive ? 'bg-primary-color2' : ''}`
      }
      to="/romance-regency-club/3"
    >
      Romance Regency Club
    </NavLink>
  </li>
</ul>

              </li>
              <li className="nav-item dropdown me-3">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="Rooms"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Rooms
                </a>
                <ul className="dropdown-menu" aria-labelledby="Rooms">
  <li>
    <NavLink
      className={({ isActive }) =>
        `dropdown-item ${isActive ? 'bg-primary-color2' : ''}`
      }
      to="/hotel/1/rooms"
    >
      Regency Rooms
    </NavLink>
  </li>
  <li>
    <NavLink
      className={({ isActive }) =>
        `dropdown-item ${isActive ? 'bg-primary-color2' : ''}`
      }
      to="/hotel/2/rooms"
    >
      Royal Rooms
    </NavLink>
  </li>
  <li>
    <NavLink
      className={({ isActive }) =>
        `dropdown-item ${isActive ? 'bg-primary-color2' : ''}`
      }
      to="/hotel/3/rooms"
    >
      Romance Rooms
    </NavLink>
  </li>
</ul>

              </li>
              {/* Beach */}
              <li className="nav-item dropdown me-3">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="Beach"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Beach
                </a>
                <ul className="dropdown-menu" aria-labelledby="Beach">
                  <li>
                    <NavLink
                      className={({ isActive }) =>
                        `dropdown-item ${isActive ? "bg-primary-color2" : ""}`
                      }
                      to="/regency-facilities/1/4"
                    >
                      Regency Beach
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className={({ isActive }) =>
                        `dropdown-item ${isActive ? "bg-primary-color2" : ""}`
                      }
                      to="/regency-facilities/2/4"
                    >
                      Royal Beach
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className={({ isActive }) =>
                        `dropdown-item ${isActive ? "bg-primary-color2" : ""}`
                      }
                      to="/regency-facilities/3/4"
                    >
                      Romance Beach
                    </NavLink>
                  </li>
                </ul>
              </li>
              {/*Aqua park */}
              <li className="nav-item me-3">
                <NavLink
                  className="nav-link"
                  to="/swimming-pools/1"
                  activeClassName="active"
                >
                  Aqua park
                </NavLink>
              </li>
              <li className="nav-item me-3">
                <NavLink
                  className="nav-link"
                  to="/about-us"
                  activeClassName="active"
                >
                  About us
                </NavLink>
              </li>
              <li className="nav-item me-3">
                <a className="nav-link" href="#">
                  Contact
                </a>
              </li>
            </ul>
      <div className="pb-3 pe-3 pb-lg-0 d-block d-lg-flex justify-content-end align-items-center col-12 col-lg-5 ">

             <div className="pe-3">
               <LanguageDropdown />
             </div>
             {user?
              <img
  onClick={() => handleUserAccount()}
  src={avatar}
  alt="avatar"
  className="w-5 rounded-5 avatar-hover"
/>
               :<div>
     <NavLink
  to="/book-room"
  className="btn bgPrimaryColor text-white rounded-5 py-1 px-2 text-sm me-2 custom-btn"
>
  Sign up
</NavLink>

               <NavLink
                to="/book-room"
                className="btn border-primary-me text-primary-me  rounded-5 py-1 px-2 text-sm custom-btn"
               >
                 Log In
               </NavLink>
               </div>
            
             }
             </div>
    </div>
  </div>
</nav> 
  </>
}
