import SwimmingPool from '../../../assets/Main/makiSwimming0.png'
import { useGetServices } from '../../../services/queries'
import { useTranslation } from 'react-i18next'; 
import './services.css'
import { BASE_URL } from '../../../services/api';
import Loader from '../../loading/loadingPage';

export const Services = ()=>{
   const { i18n } = useTranslation();
   const storedLang = i18n.language;
   const {data:services,error,isLoading} = useGetServices(storedLang)
   if (error) {
      console.log('error',error); 
   }
if (isLoading) {
   return <Loader/>
}
   return <>
   <div className='text-center text-4B86B4 pt-5'>
      <h2 className='h1'>Our Services</h2>
   </div>
   <section className="row justify-content-center align-items-center container m-auto gy-4">
      {services?.map((service)=>(
<div className="col-md-3">
   <div className='servicesItemBg p-5 mx-1 rounded-5 text-center'>
   <div className='d-flex justify-content-center align-items-center pb-2'>
   <img 
     style={{ width: "100px", height: "100px", objectFit: "cover" }}
    src={BASE_URL+service.imageUrl} alt={service.name} />
   </div>
   <h5 className='text-4B86B4'>{service.name}</h5>
   </div>
</div>
      ))}

   </section>
   </> 
      
}