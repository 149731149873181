import animation from "./animation.module.css";
import { useTranslation } from 'react-i18next'; 

export function Facilities() {
  const { t } = useTranslation();

    return <>
        <section className={`${animation.animationStyle} p-5 mt-5 m-auto`}>
          <div className="container pb-5 ">
            <h1 className={`title ${animation.hFontStyle}`}>
              <span className={`${animation.span} ${animation.spanH1} h1`}>
                {t("facilities")}
              </span>
              <span className={`${animation.span} ${animation.spanH1} h1`}>
                {t("for your comfort")}
              </span>
            </h1>
          </div>
        </section>
      </>
  }

