import en from '../../assets/flags/twemoji_flag-united-states.png'
import it from '../../assets/flags/twemoji_flag-italy.png'
import ru from '../../assets/flags/twemoji_flag-russia.png'
import pl from '../../assets/flags/twemoji_flag-poland.png'
import React, { useState } from 'react';
import './LanguageDropdown.css';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

const LanguageDropdown = () => {
  const { i18n } = useTranslation();
  const storedLang = localStorage.getItem('language');
  const storedFlag = localStorage.getItem('flag');
  const [currLang,setCurrLang]= useState({
   name:"English",
   flag:en
  })
// console.log(i18n.language);
useEffect(()=>{
  if (storedLang && storedFlag) {
    setCurrLang({
      name: storedLang,
      flag: storedFlag,
    });
  }
},[])
const changeLanguage = (lng, lang2, flag) => {
  i18n.changeLanguage(lng);
  setCurrLang({
    name: lang2,
    flag: flag,
  });

  // Store language and flag in localStorage
  localStorage.setItem('language', lang2);
  localStorage.setItem('flag', flag);
};

  return (
    <div className="dropdown">
      <button 
        className="btn btn-light dropdown-toggle d-flex align-items-center" 
        type="button" 
        id="dropdownMenuButton" 
        data-bs-toggle="dropdown" 
        aria-expanded="false"
      >
        <span role="img" aria-label="flag">
        <img src={currLang.flag} alt="" />
        </span>
        <span className="ms-2">{currLang.name}</span>
      </button>

      <ul className="dropdown-menu py-0" aria-labelledby="dropdownMenuButton">
        <li>
          <button 
            className={`dropdown-item d-flex align-items-center rounded-top ${i18n.language === 'en' ? 'bg-primary-color2' : ''}`} 
            onClick={() => changeLanguage('en','English',en)}
          >
            <span role="img" aria-label="flag">
               <img src={en} alt="" />
            </span>
            <span className="ms-2">English</span>
          </button>
        </li>
        <li>
          <button 
            className={`dropdown-item d-flex align-items-center ${i18n.language === 'it' ? 'bg-primary-color2' : ''}`} 
            onClick={() => changeLanguage('it','Italy',it)}
          >
            <span role="img" aria-label="flag">
            <img src={it} alt="" />

            </span>
            <span className="ms-2">Italy</span>
          </button>
        </li>
        <li>
          <button 
            className={`dropdown-item d-flex align-items-center ${i18n.language === 'ru' ? 'bg-primary-color2' : ''}`} 
            onClick={() => changeLanguage('ru','Russian',ru)}
          >
            <span role="img" aria-label="flag">
            <img src={ru} alt="" />
            </span>
            <span className="ms-2">Russian</span>
          </button>
        </li>
        <li>
          <button 
            className={`dropdown-item d-flex align-items-center rounded-bottom ${i18n.language === 'pl' ? 'bg-primary-color2' : ''}`} 
            onClick={() => changeLanguage('pl','Poland',pl)}
          >
            <span role="img" aria-label="flag">
            <img src={pl} alt="" />

            </span>
            <span className="ms-2">Poland</span>
          </button>
        </li>
      </ul>
    </div>
  );
};

export default LanguageDropdown;
