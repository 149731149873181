import axios from "axios";

 export const BASE_URL = 'https://regencyapi.mubasher.com.eg';
 
 export const _axios = axios.create({baseURL: BASE_URL})

 // Function to set the token
export const setAuthToken = (token) => {
  if (token) {
    // Apply the token to every request
    _axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    // Remove the token from the headers if there's no token
    delete _axios.defaults.headers.common['Authorization'];
  }
};

 export const signIn = async(data)=>{
  const response = await _axios.post('/api/Auth/Login', data);
  const token = response.data; 
  console.log(token);
  setAuthToken(token);
  return response;
}

export const signUp = async(data)=>{
  const response = await _axios.post('/api/Auth/Regiter', data);
  return response;
}

export const roomSearch = async(data)=>{
  const response = await _axios.post('/api/Room/RoomSearch', data);
  return response;
}

export const GetAllRoomTypes = async()=>{
  const response = await _axios.get('/RoomTypes/GetAllRoomTypes');
  return response;
}

 
