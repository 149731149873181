
import pool from "../../../assets/swimming pools/6 Swimming Pools/Children_s Pool at the Water Park/Aquapark - .jpg";
import pool2 from "../../../assets/swimming pools/6 Swimming Pools/Relaxing Pool/sunset pool.JPG";
import pool3 from "../../../assets/swimming pools/6 Swimming Pools/Main Pool at the Water Park/Aquapark overview 5 - Copy - Copy.jpg";
import romancePool7 from "../../../assets/swimming pools/romance/Swimming pool.jpg";
import romancePool8 from "../../../assets/swimming pools/romance/DSC_0050.JPG";
import { Link, useParams } from 'react-router-dom'

export const HotelSwimmingPools = ()=>{
   const {hotelId} = useParams()
   const regency = "Regency Plaza Swimming Pools";
   const royal = "Royal Regency Swimming Pools";
   const romance = "Romance Regency Swimming Pools";
   return<>
      <section className="container my-5">
      <div className="">
         <div className=" d-flex">
            <div className="col-10">
            {hotelId == 1 && <h2 className=" fw-bold">{regency}</h2>}
              {hotelId == 2 && <h2 className=" fw-bold">{royal}</h2>}
              {hotelId == 3 && <h2 className=" fw-bold">{romance}</h2>}
            </div>
            <div className="col-2">
            <Link to={`/swimming-pools/${hotelId}`} className="fs-5 fw-semibold text-decoration-none textMain">View all</Link>
            </div>
         </div>
         <div className="col-6">
         <p>Enjoy a Refreshing Dip Across all our Hotels</p>
         </div>
      </div>
      <div className="row py-4">
<div className="col-md-4">
   <div className="shadow rounded-5 p-3">
      <img src={hotelId==3? romancePool7: pool} className="w-100 rounded-5 hover-effect  hotelFixedImage rounded-top-4" alt="" />
      <div className="ps-2 pb-3">
      <h4 className='py-3'>Kids Area</h4>
      <Link to={`/swimming-pools/${hotelId}`} className="btn bgPrimaryColor custom-btn rounded-5 text-white pb-2 px-3">
      More Details
                        </Link>
      </div>
   </div>
</div>
<div className="col-md-4">
   <div className="shadow rounded-5 p-3">
      <img src={pool2} className="w-100 rounded-5 hover-effect hotelFixedImage rounded-top-4" alt="" />
      <div className="ps-2 pb-3">
      <h4 className='py-3'>Sunset Pool</h4>
      <Link to={`/swimming-pools/${hotelId}`} className="btn bgPrimaryColor custom-btn rounded-5 text-white pb-2 px-3">
      More Details
                        </Link>
      </div>
   </div>
</div>
<div className="col-md-4">
   <div className="shadow rounded-5 p-3">
      <img src={hotelId==3?romancePool8:pool3} className="w-100 rounded-5 hover-effect hotelFixedImage rounded-top-4" alt="" />
      <div className="ps-2 pb-3">
      <h4 className='py-3'>Aqua park</h4>
      <Link to={`/swimming-pools/${hotelId}`} className="btn bgPrimaryColor custom-btn rounded-5 text-white pb-2 px-3">
      More Details
       </Link>
      </div>
   </div>
</div>
      </div>
   </section>
   </>
}